import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LeftPane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree = _resolveComponent("tree")!
  const _component_con_box = _resolveComponent("con-box")!
  const _component_tree2 = _resolveComponent("tree2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_con_box, {
      title: _ctx.jieguo.title,
      slotH: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.jieguo,
          onChange: _ctx.jieguo.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.xzqhState.title,
      slotH: 10
    }, {
      default: _withCtx(() => [
        (_ctx.showTree)
          ? (_openBlock(), _createBlock(_component_tree2, {
              key: 0,
              state: _ctx.xzqhState,
              onChange: _ctx.xzqhState.handleItemClick
            }, null, 8 /* PROPS */, ["state", "onChange"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.juedingriqi.title,
      slotH: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.juedingriqi,
          onChange: _ctx.juedingriqi.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ]))
}