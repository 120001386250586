
import { defineComponent, ref, reactive, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
// import * as _ from 'lodash'
import { debounce } from "throttle-debounce"

import httpRequest from '@/service'

import conBox from './cpns/conBox.vue'
import list from './cpns/list.vue'
import tree from './cpns/tree.vue'
import tree2 from './cpns/tree2.vue'

export default defineComponent({
  name: 'LeftPane',
  components: {
    conBox,
    list,
    tree,
    tree2
  },
  setup() {
    const store = useStore()
    const initCount = debounce(80, () => {
      jieguo.loading++
      httpRequest
        .post({ url: 'fyCaseResultCount', data: store.getters.getFyCaseLibraryApiParam })
        .then((res: any) => {
          jieguo.data = res.data.map(i=>{
            return {
              id:i.code,
              name:i.name,
              parentId:null,
              value:i.count,
            }
          })
        }).finally(() => {
          jieguo.loading--
        })
      juedingriqi.loading++
      httpRequest
        .post({ url: 'fyCaseTimeCount', data: {...store.getters.getFyCaseLibraryApiParam, decisionYear: ''} })
        .then((res: any) => {
          juedingriqi.data = res.data.map(i=>{
            return {
              id:i.code,
              name:i.name,
              parentId:null,
              value:i.count,
            }
          })
        }).finally(() => {
          juedingriqi.loading--
        })
    })
    initCount()
    watch(
      () => store.getters.getFyCaseLibraryApiParam,
      () => {
        initCount()
      }
    )
    // 复议决定
    const jieguo: any = reactive({
      title: '复议决定',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addFyResultApiParam', {
          id: 'reconsiderResult' + item.id,
          param: 'reconsiderResult',
          value: item.id,
          label: item.name,
          replace: true
        })
      }
    })
    // 决定日期
    const juedingriqi: any = reactive({
      title: '决定日期',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addFyResultApiParam', {
          id: 'decisionYear' + item.id,
          param: 'decisionYear',
          value: item.id,
          label: item.name,
          replace: true
        })
      }
    })
    // 所属区划
    const xzqhState: any = reactive({
      title: '所属区划',
      data: [],
      defaultId: store.getters.getFyCaseLibraryApiParam.fullAdminDivisionCode,
      level: store.getters.getFyCaseLibraryApiParam.levelCode,
      parentId: '',
      nextLevel: '',
      loading: true,
      getData() {
        xzqhState.loading = true
        const form = JSON.parse(JSON.stringify(store.getters.getFyCaseLibraryApiParam))
        form.fullAdminDivisionCode = ''
        form.levelCode = ''
        httpRequest
          .post({
            url: 'fyCaseAreaCount',
            data: form
          })
          .then((res: any) => {
            xzqhState.data = res.data
            xzqhState.level = xzqhState.data[0].level
            xzqhState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(item: any) {
        xzqhState.defaultId = item.fullAdminDivisionCode
        xzqhState.level = item.levelCode
        store.commit('addFyResultApiParam', {
          id: 'fullAdminDivisionCode' + item.fullAdminDivisionCode,
          param: 'fullAdminDivisionCode',
          value: item.fullAdminDivisionCode,
          label: item.name,
          replace: true
        })
        store.commit('addFyResultApiParam', {
          id: 'levelCode' + item.levelCode,
          param: 'levelCode',
          value: item.levelCode,
          replace: true
        })
      }
    })
    xzqhState.getData()
    const showTree = ref(true)
    watch(
      () => store.getters.getFyCaseLibraryApiParam,
      (newV, oldV) => {
        if (newV.fullAdminDivisionCode && newV.fullAdminDivisionCode !== oldV.fullAdminDivisionCode) return;
        [xzqhState.defaultId, xzqhState.level] = [
          newV.fullAdminDivisionCode,
          newV.levelCode
        ]
        // getData()是为了重新获取括号后面的数字值，但是在展开的时候会导致无限递归，
        // 所以每次先注销组件重新渲染一下
        showTree.value = false
        nextTick(() => {
          showTree.value = true
          xzqhState.getData()
        })
      },{ deep: true }
    )
    return {
      jieguo,
      showTree,
      xzqhState,
      juedingriqi
    }
  }
})
