
import { computed, defineComponent, nextTick, onBeforeUnmount, ref, watch } from 'vue'
import httpRequest from '@/service'
import type Node from 'element-plus/es/components/tree/src/model/node'

interface Tree {
  name: string
  id: string
  value: number
  level: string
  leaf?: boolean
  children?: Tree[]
  parentId?: string
  nextLevel?: string
}

export default defineComponent({
  name: 'tree',
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      }
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  emit: ['change'],
  setup(props, { emit }) {
    const expandArr = ref<string[]>([])
    const checkedArr = ref<string[]>([])
    const defaultProps = {
      label: 'name',
      value: 'value',
      children: 'children',
      isLeaf: 'leaf'
    }
    const defaultKey = ref(props.state.defaultId)
    const popularTree = ref()
    const widthCal = (e: any) => {
      return ` cal(100%-${String(e).length}*8)`
    }
    onBeforeUnmount(() => {
      if (props.state.key === 'institutionId') {
        sessionStorage.getItem('institutionParent') &&
        sessionStorage.removeItem('institutionParent')
      }
    })
    watch(
      () => [props.state.loading, props.state.defaultId],
      () => {
        if (props.state.key === 'institutionId') {
          expandArr.value = JSON.parse(
            sessionStorage.getItem('institutionParent') as string || '[]'
          )
        }
        nextTick(() => {
          defaultKey.value = props.state.defaultId
          if (defaultKey.value === '') defaultKey.value = null
          popularTree.value.setCurrentKey(defaultKey.value)
          checkedArr.value = [defaultKey.value]
        })
      }
    )

    const loadNode = (node: Node, resolve: (data: Tree[]) => void) => {
      if (!node.level) return
      let curId = node.data.id ? node.data.id : ''
      let nextLevel = node.data.nextLevel ? node.data.nextLevel : ''
      httpRequest
        .post({
          url: props.state.url,
          params: {
            [props.state.key]: curId,
            [props.state.keyLevel]: nextLevel
          }
        })
        .then((res: any) => {
          nextTick(() => {
            defaultKey.value = props.state.defaultId
            if (defaultKey.value === '') defaultKey.value = null
            popularTree.value.setCurrentKey(defaultKey.value)
            checkedArr.value = [defaultKey.value]
          })
          return resolve(res.data)
        })
        .catch((err) => {
          console.error(err)
        })
    }
    const handleNodeClick = (data: any, Node: any) => {
      expandArr.value = []
      const obj = {
        id: data.id,
        level: data.level,
        parentId: data.parentId,
        nextLevel: data.nextLevel,
        type: props.state.key,
        name: props.state.title + '：' + data.name
      }

      function seekParent(o: any) {
        o.parent.level > 0 && expandArr.value.push(o.parent.data.id)
        o.parent.level > 1 && seekParent(o.parent)
      }

      seekParent(Node)
      if (props.state.key === 'institutionId') {
        sessionStorage.setItem(
          'institutionParent',
          JSON.stringify(expandArr.value)
        )
      }
      checkedArr.value = [data.id]
      emit('change', data)
    }

    const treeData = computed(() => {
      const childMap: any = {}
      const data = props.state.data
      data.forEach((item: any) => {
        childMap[`c_${item.parentId}`] = childMap[`c_${item.parentId}`] || []
        childMap[`c_${item.parentId}`].push(item)
      })
      data.forEach((item: any) => {
        const children = childMap[`c_${item.id}`]
        if (children) {
          item.children = children
          // item.children.forEach((c: any) => {
          //   c.parent = item
          // })
        }
      })
      return childMap[`c_null`]
    })
    return {
      treeData,
      expandArr,
      checkedArr,
      popularTree,
      defaultProps,
      defaultKey,
      handleNodeClick,
      loadNode,
      widthCal
    }
  }
})
