import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e62740bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree" }
const _hoisted_2 = { class: "item-column" }
const _hoisted_3 = { class: "tree-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, { height: "100%" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tree, {
          ref: "popularTree",
          data: _ctx.state.data,
          props: _ctx.defaultProps,
          "node-key": "fullAdminDivisioncode",
          lazy: _ctx.lazy,
          load: _ctx.loadNode,
          "check-strictly": "",
          "check-on-click-node": "",
          "expand-on-click-node": false,
          "current-node-key": _ctx.defaultKey,
          "default-expanded-keys": _ctx.expandArr,
          "default-checked-keys": _ctx.checkedArr,
          "highlight-current": "",
          onNodeClick: _ctx.handleNodeClick
        }, {
          default: _withCtx(({ node, data }) => [
            _createVNode(_component_el_tooltip, {
              content: node.label+'（'+data.value.toLocaleString()+'）',
              placement: "top",
              effect: "light",
              "show-after": 1000
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", {
                    class: "tree-label",
                    style: _normalizeStyle({ width: _ctx.widthCal(data.value) })
                  }, _toDisplayString(node.label), 5 /* TEXT, STYLE */),
                  _createElementVNode("span", _hoisted_3, "（" + _toDisplayString(data.value.toLocaleString()) + "）", 1 /* TEXT */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "props", "lazy", "load", "current-node-key", "default-expanded-keys", "default-checked-keys", "onNodeClick"])
      ]),
      _: 1 /* STABLE */
    })
  ])), [
    [_directive_loading, _ctx.state.loading]
  ])
}