import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b889869"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <div class=\"title\">结论：</div> "),
    _createElementVNode("div", {
      class: _normalizeClass({ 'list-item-yaodian-content': true, 'expand':$setup.expand })
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          ref: "contentRef",
          innerHTML: $props.data
        }, null, 8 /* PROPS */, _hoisted_1),
        _withDirectives(_createElementVNode("div", {
          class: "expand-btn",
          onClick: $setup.handleExpand
        }, _toDisplayString($setup.expand?'收起':'... 展开'), 513 /* TEXT, NEED_PATCH */), [
          [_vShow, $setup.showBtn]
        ])
      ])
    ], 2 /* CLASS */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}