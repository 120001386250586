<!--
 * @Author wuyp1
 * @Date 2024年3月27日
 * @Description 人民法院案例库检索结果
-->
<template>
  <div class="common-main">
    <bread-crumb></bread-crumb>
    <div class="m-content">
      <div class="m-left">
        <left-pane></left-pane>
      </div>
      <div class="m-right">
        <right-pane></right-pane>
      </div>
    </div>
  </div>
</template>

<script setup>
import BreadCrumb from '@/components/breadCrumb/index.vue'
import leftPane from './leftPane.vue'
import rightPane from './searchResult.vue'
</script>

<style lang="less" scoped>
.m-right {
  width: 885px;
}
</style>