
import { defineComponent, nextTick, onBeforeUnmount, ref, watch } from 'vue'
import httpRequest from '@/service'
import type Node from 'element-plus/es/components/tree/src/model/node'
import { useStore } from 'vuex'
interface Tree {
  name: string
  fullAdminDivisioncode: string
  value: number
  level: string
  leaf?: boolean
  children?: Tree[]
  parentId?: string
  levelCode?: string
}

export default defineComponent({
  name: 'tree',
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      }
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  emit: ['change'],
  setup(props, { emit }) {
    const store = useStore()
    const expandArr = ref<string[]>([])
    const checkedArr = ref<string[]>([])
    const defaultProps = {
      label: 'name',
      value: 'value',
      children: 'children',
      isLeaf: 'leaf'
    }
    const defaultKey = ref(props.state.defaultId)
    const popularTree = ref()
    const widthCal = (e: any) => {
      return ` cal(100%-${String(e).length}*8)`
    }
    onBeforeUnmount(() => {
      if (props.state.key === 'institutionId') {
        sessionStorage.getItem('institutionParent') &&
        sessionStorage.removeItem('institutionParent')
      }
    })
    watch(
      () => [props.state.loading, props.state.defaultId],
      () => {
        if (props.state.key === 'institutionId') {
          // console.log(11111111);
          expandArr.value = JSON.parse(
            sessionStorage.getItem('institutionParent') as string || '[]'
          )
        }
        nextTick(() => {
          defaultKey.value = props.state.defaultId
          if (defaultKey.value === '') defaultKey.value = null
          popularTree.value.setCurrentKey(defaultKey.value)
          checkedArr.value = [defaultKey.value]
        })
      }
    )

    const loadNode = (node: Node, resolve: (data: Tree[]) => void) => {
      console.log("🚀 ~ loadNode ~ node:", node)
      if (!node.level || node.isLeaf) return resolve([])
      const form = JSON.parse(JSON.stringify(store.getters.getFyCaseLibraryApiParam))
      form['fullAdminDivisionCode'] = node.data.fullAdminDivisionCode || ''
      form['levelCode'] = node.data.levelCode || ''
      httpRequest
        .post({
          url: 'fyCaseAreaCount',
          data: form
        })
        .then((res: any) => {
          nextTick(() => {
            defaultKey.value = props.state.defaultId
            if (defaultKey.value === '') defaultKey.value = null
            popularTree.value.setCurrentKey(defaultKey.value)
            checkedArr.value = [defaultKey.value]
          })
          return resolve(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const handleNodeClick = (data: any, Node: any) => {
      expandArr.value = []
      function seekParent(o: any) {
        o.parent.level > 0 && expandArr.value.push(o.parent.data.fullAdminDivisioncode)
        o.parent.level > 1 && seekParent(o.parent)
      }
      seekParent(Node)
      checkedArr.value = [data.fullAdminDivisioncode]
      emit('change', data)
    }

    return {
      expandArr,
      checkedArr,
      popularTree,
      defaultProps,
      defaultKey,
      handleNodeClick,
      loadNode,
      widthCal
    }
  }
})
